import '../../../style/Login/login.css';
import { useForm } from 'react-hook-form';

const RegisterLogin = ({ handleContinue }) => {
  const {
    register, handleSubmit, setError, formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    handleContinue({ first_name: data.first_name, last_name: data.last_name, email: data.email, password: data.password }).then((result) => {
      if (!result) {
        handleSubmit(handleContinue);
      } else {
        setError("email", {
          type: "manual",
          message: "El email ingresado ya se encuentra en uso",
        });
      }
    }).catch((error) => {
      console.log("Error:", error);
    });

  }

  return (
    <div className="RegisterLogin">

      {errors.email && (
        <div className="alert alert-danger">{errors.email.message}</div>
      )}

      {errors.password && (
        <div className="alert alert-danger">{errors.password.message}</div>
      )}

      <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="containerEmailLogin">
          <input {...register('first_name', { required: '*El campo de nombre es obligatorio' })} type="text" className="login-input" placeholder="Nombre" />
          <input {...register('last_name', { required: '*El campo de apellido es obligatorio' })} type="text" className="login-input" placeholder="Apellido" />
          <input {...register('email', { required: '*El campo de email es obligatorio' })} type="email" className="login-input" placeholder="Email" />
          <input {...register('password', { required: '*El campo de la contraseña es obligatorio' })} type="password" className="login-input" placeholder="Contraseña" />
          <input {...register('repatPassword', { required: '*El campo de repetir contraseña es obligatorio' })} type="password" className="login-input" placeholder="Repetir contraseña" />
        </div>
      </form>

      <span className='termsAndConditions'> Ver los <a style={{ textDecoration: 'underline', color: '#FFFFFF' }} href="">términos y condiciones</a></span>
      <button type="submit" className="apiLoginButton" onClick={handleSubmit(onSubmit)} >Enviar</button>
    </div >
  );
}

export default RegisterLogin;
