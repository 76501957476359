import { Logo } from '../../../img';
import '../../../style/Login/login.css';
import { useForm } from 'react-hook-form';
import { endpointLogin6 } from '../../../endpoints';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Vector } from '../../../img';
import { useState } from 'react';


const SignIn = () => {

    const [loading, setLoading] = useState(false);


    const {
        register, handleSubmit, setError, formState: { errors },
    } = useForm();

    const navigate = useNavigate();


    const handleUser = () => {
        navigation.navigate('/user', {
            interests: { id: 1, name: 'Music' }
        });
    };

    const handleLogIn = async (data) => {
        setLoading(true);
        await axios.post(endpointLogin6, { email: data.email, password: data.password }).then((res) => {
            if (res.data.status == 'ok') {
                setLoading(false);
                localStorage.setItem('sessionToken', res.data.token);
                handleUser();
            } else {
                setLoading(false);
                setError("email", {
                    type: "manual",
                    message: "Correo electronico o contraseña incorrecta.",
                });
            }
        })
    }

    return (
        <>
            <div className='signInContainer'>
                <div className='containerSignInBase'>
                    <div className="RegisterLogin">
                        <p className='titleLoginRegister'>Iniciar sesión</p>
                        <form className="registerForm">
                            <div className="containerEmailLogin">
                                <div className='containerInformationLogin'>
                                    <div className='containerEmailLogin'>
                                        <input {...register('email', { required: '*El campo de email es obligatorio' })} type="email" className="login-input" placeholder="Email" />
                                        <input {...register('password', { required: '*El campo de la contraseña es obligatorio' })} type="password" className="login-input " placeholder="Contraseña" />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div>
                            {errors.email && (
                                <div className="error-message">{errors.email.message}</div>
                            )}
                            {errors.password && (
                                <div className="error-message">{errors.password.message}</div>
                            )}
                        </div>
                        <div className="forgotPassword">
                            <a id="forgotPassword" style={{ textDecoration: 'underline', color: '#FFFFFF' }} href="">Olvidé mi contraseña</a>
                        </div>
                        <button type="submit" className="apiLoginButton" onClick={handleSubmit(handleLogIn)}>Enviar</button>
                    </div>
                </div>
                <div className='subContainerSignInBase'>
                    <div className="RegisterLogin">
                        <p className='titleLoginRegister'>Continuar como invitado</p>
                        <form className="registerForm">
                            <div className="containerEmailLogin">
                                <div className='containerInformationLogin'>
                                    <div className='containerEmailLogin'>
                                        <input type="email" className="login-input" placeholder="Email" />
                                        <div style={{ float: 'right', backgroundColor: 'white', width: '30px', height: '30px', borderRadius: '10%', marginRight: '10px' }}>
                                            <img src={Vector} alt="flecha" style={{ paddingTop: '6px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {errors.guest && (
                            <div className="alert alert-danger">{"errors.guest.message"}</div>
                        )}
                    </div>
                    <button type="submit" className="createAccountButton" onClick={""}>Crear una cuenta</button>
                </div>
            </div >
        </>
    )
}

export default SignIn;