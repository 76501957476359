import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../style/Login/login.css';
import { connect } from 'react-redux';
import {
  WhatsappShareButton,
} from 'react-share';
import { setOpenLogin } from '../../../state/AppState';
import {
  WpCompartirLogin,
  CopiarLogin,
} from '../../../img';
import { endpointLogin5 } from '../../../endpoints';
import axios from 'axios';


function Welcome({ setOpenLoginState, referralLink, loginToken }) {
  const shareUrl = { referralLink };
  const [copy, setCopy] = useState(false);

  const changeLoginStatus = async () => {
    try {
      const detailsData = {
        token: loginToken
      };
      await axios.post(endpointLogin5, detailsData).then((res) => {
        localStorage.setItem('sessionToken', res.data.token);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    changeLoginStatus();
  }, []);

  return (
    <div className="welcome">
      <div className="welcomeContainer">
        <div className="urlLogin">
          <div>
            <div>
              ¡Gracias por registrarte en Voy!
              Ya podés acceder a toda la oferta de espectáculos y eventos culturales.
              ¡Seleccioná todos los que te gusten!
            </div>
          </div>
        </div>
        <div className="buttonsWelcome">
          <button
            onClick={() => {
              setOpenLoginState(false);
            }}
            type="button"
            className="continueLoginButton"
          >
            Ver eventos
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  referralLink: state.app.referralLink,
});
export default connect(mapStateToProps, {
  setOpenLoginState: setOpenLogin,
})(Welcome);
