/* eslint-disable linebreak-style */
import { React, useEffect } from 'react';
import {
  Route, Routes,
  useSearchParams,
} from 'react-router-dom';
import { connect } from 'react-redux';
// import axios from 'axios';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import { Helmet } from 'react-helmet';
import {
  applyTypeFilter,
  getData, getRestaurants, filtersWipe, applyGenreFilter,
} from './service';
import Giveaway from './componentes/Site/Giveaway';
import Giveaways from './componentes/Site/Giveaways';

import './App.css';
import './style/Layouts/loader.css';
import { Loader } from './img';
import {
  setEvents, setBands, setLocations, setHighlights, setRestaurants,
  setEventTypes, setBandTypes, setGenres, setInterests, setOtherData, setSponsors,
  setFilters, setLoading, setCities, setLocalities, setGiveaways, setSurveys, setReferredByCode, setOpenLogin,
} from './state/AppState';
import Index from './componentes/Site/Index';
import MapView from './componentes/Site/MapView';
import EventsView from './componentes/Site/EventsView';
import EventInformation from './componentes/Site/EventInformation';
import Terms from './componentes/Site/Terms';
import TermsDraw from './componentes/Site/TermsDraw';
import TermsDrawSummer from './componentes/Site/TermsDrawSummer';
import PrivacyPolicy from './componentes/Site/PrivacyPolicy';
import Nosotros from './componentes/Site/Nosotros';
import Contact from './componentes/Site/Contact';
import FrequentQuestions from './componentes/Site/FrequentQuestions';
import LocationInformation from './componentes/Site/LocationInformation';
import BandInformation from './componentes/Site/BandInformation';
import SignIn from './componentes/Login/components/signIn.jsx'
import User from './componentes/User/User'

function PageContainer({
  setEventsState, setBandsState, setLocationsState, setHighlightsState, setRestaurantsState,
  setEventTypesState, setBandTypesState, setGenresState, setInterestsState, setOtherDataState,
  setSponsorsState, setLoadingState, setOpenLoginState, locations, events, isLoading,
  setCitiesState, setLocalitiesState, setGiveawaysState, setSurveysState, setFiltersState, setReferredByCodeState,
}) {
  useEffect(() => {
    const getServerData = async () => {
      const response = await getData();
      setEventsState(response.data.events);
      setBandsState(response.data.bands);
      setLocationsState(response.data.locations);
      setHighlightsState(response.data.highlightEvents);
      setEventTypesState(response.data.eventTypes);
      setBandTypesState(response.data.bandTypes);
      setGenresState(response.data.genres);
      setOtherDataState(response.data.otherData);
      setInterestsState({
        topInterests: response.data.topInterests,
        bottomInterests: response.data.bottomInterests,
      });
      setSponsorsState(response.data.sponsors);
      setCitiesState(response.data.cities);
      setLocalitiesState(response.data.localities);
      setGiveawaysState(response.data.giveaways);
      setSurveysState(response.data.surveys);
      setLoadingState(false);
    };
    if (events?.length === 0 || !events) {
      setLoadingState(true);
    }
    getServerData();
  }, []);

  useEffect(() => {
    if (locations) {
      setRestaurantsState(getRestaurants(locations));
    }
  }, [locations]);

  useEffect(() => {
    ReactGA.initialize('G-2H03TZFFPH');
    ReactGA.send('pageview');
    ReactPixel.init('1301859967290085', {}, { autoConfig: true, debug: false });
    ReactPixel.pageView();

    const tagManagerArgs = {
      gtmId: 'GTM-MGW779M',
    };

    TagManager.initialize(tagManagerArgs);

    hotjar.initialize(3261045, 6);

    if (hotjar.initialized()) {
      hotjar.stateChange('/');
    }
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const isAuthenticated = !!localStorage.getItem('sessionToken');

  useEffect(() => {
    filtersWipe(setFiltersState);

    if (searchParams.get('registrarme') !== null) {
      setOpenLoginState(true);
    }
    setReferredByCodeState(searchParams.get('ref'));

    const filtrar = searchParams.get('filtrar');

    if (filtrar !== null) {
      if (filtrar === 'carnaval') {
        applyTypeFilter(5, setFiltersState);
      } else if (filtrar === 'concurso-oficial') {
        applyGenreFilter('82', 5, setFiltersState);
      } else if (filtrar === 'tablados') {
        applyGenreFilter('83', 5, setFiltersState);
      } else if (filtrar === 'festivales') {
        applyGenreFilter('84', 5, setFiltersState);
      } else if (filtrar === 'llamadas') {
        applyGenreFilter('85', 5, setFiltersState);
      }
    }
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>VOY ☆ Todos los Eventos Culturales del Uruguay</title>
        <meta name="title" content="VOY ☆ Todos los Eventos Culturales del Uruguay" />
        <meta name="description" content="Toda la Cartelera de Eventos del Uruguay. Encontrá entradas con descuentos. Música, Teatro, Cine, Fiestas y más!" />
        <meta property="og:title" content="VOY ☆ Todos los Eventos Culturales del Uruguay" />
        <meta property="og:description" content="Toda la Cartelera de Eventos del Uruguay. Encontrá entradas con descuentos. Música, Teatro, Cine, Fiestas y más!" />
        <meta property="twitter:title" content="VOY ☆ Todos los Eventos Culturales del Uruguay" />
        <meta property="twitter:description" content="Toda la Cartelera de Eventos del Uruguay. Encontrá entradas con descuentos. Música, Teatro, Cine, Fiestas y más!" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/ver-eventos/" element={<EventsView />} />
        <Route path="/ver-eventos/:id" element={<EventsView />} />
        <Route path="/evento/:link" element={<EventInformation />} />
        <Route path="/locacion/:link" element={<LocationInformation />} />
        <Route path="/artista/:link" element={<BandInformation />} />
        <Route path="/view-map" element={<MapView />} />
        <Route path="/terminos-y-condiciones" element={<Terms />} />
        <Route path="/politica-de-privacidad" element={<PrivacyPolicy />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/preguntas-frecuentes" element={<FrequentQuestions />} />
        <Route path="/quienes-somos" element={<Nosotros />} />
        <Route path="/terminos-y-condiciones-sorteo" element={<TermsDraw />} />
        <Route path="/terminos-y-condiciones-sorteo-verano" element={<TermsDrawSummer />} />
        <Route path="/sorteo/participar/:link" element={<Giveaway />} />
        <Route path="/sorteos/participar" element={<Giveaways />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route element={isAuthenticated && <User />} path="/user" />
      </Routes>
      <div id="loader" className={isLoading ? 'show' : ''}>
        <img src={Loader} alt="loader" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  highlights: state.app.highlights,
  restaurants: state.app.restaurants,
  eventTypes: state.app.eventTypes,
  filters: state.app.filters,
  isLoading: state.app.isLoading,
});

export default connect(mapStateToProps, {
  setReferredByCodeState: setReferredByCode,
  setEventsState: setEvents,
  setBandsState: setBands,
  setLocationsState: setLocations,
  setHighlightsState: setHighlights,
  setRestaurantsState: setRestaurants,
  setEventTypesState: setEventTypes,
  setBandTypesState: setBandTypes,
  setGenresState: setGenres,
  setOtherDataState: setOtherData,
  setInterestsState: setInterests,
  setSponsorsState: setSponsors,
  setFiltersState: setFilters,
  setLoadingState: setLoading,
  setOpenLoginState: setOpenLogin,
  setCitiesState: setCities,
  setLocalitiesState: setLocalities,
  setGiveawaysState: setGiveaways,
  setSurveysState: setSurveys,
})(
  PageContainer,
);
