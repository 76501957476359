/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { endpointFrontData } from './endpoints';
import {
  MovieIcon,
  MusicIcon,
  TheatreIcon,
  OtherEventsIcon,
  CarnavalIcon,
  FiestasIcon,
} from './img';

/* Trae datos de todos los eventos */
const getData = async () => {
  try {
    const response = await axios.post(
      endpointFrontData,
    );
    return response;
  } catch (error) {
    return error;
  }
};

function getIdFromLink(link) {
  const linkArray = link.split('-');
  const id = linkArray[linkArray.length - 1];
  return id;
}

function getEvent(id, events) {
  let event = null;
  event = events?.find(
    (e) => e.id === id,
  );
  return event;
}

function getLocation(id, locations) {
  let location = null;
  location = locations?.find(
    (l) => parseFloat(l.id) === parseFloat(id),
  );
  return location;
}

function getLocationBranches(id, locations) {
  let eventLocations = [getLocation(id, locations)];
  const branches = locations?.filter((location) => {
    if (location.main_location_id === id) {
      return location;
    }
  });
  eventLocations = eventLocations.concat(branches);
  return eventLocations;
}

function getGiveaway(id, giveaways) {
  let giveaway = null;
  giveaway = giveaways.find(
    (g) => g.id === id,
  );
  return giveaway;
}

function getEventHighlights(highlightIds, events) {
  const highlights = [];
  if (events?.length > 0) {
    let event = false;
    highlightIds.forEach((highlightId) => {
      event = getEvent(highlightId.event_id, events);
      if (event) { highlights.push(event); }
    });
  }
  return highlights;
}

function getRestaurants(locations) {
  let result = [...locations];
  result = result.filter(
    (location) => location.is_restaurant === '1' && location.locationPromotions?.length > 0,
  );
  return result;
}

function getRestaurantsWithoutBranches(restaurants) {
  let result = [...restaurants];
  result = result.filter((restaurant) => {
    if (restaurant.main_location_id === '0') {
      return restaurant;
    }
  });
  return result;
}

function getSponsorsWithEvents(sponsors) {
  let result = [...sponsors];
  result = result.sort((a, b) => b.eventPromotions - a.eventPromotions);
  result = result.filter((sponsor) => (sponsor.eventPromotions > 0));
  return result;
}

function getSponsorsWithRestaurants(sponsors) {
  let result = [...sponsors];
  result = result.sort((a, b) => b.locationPromotions - a.locationPromotions);
  result = result.filter((sponsor) => (sponsor.locationPromotions > 0));
  return result;
}

const getLocationsForMarkers = (events, allLocations) => {
  const locationsMap = new Map();
  const locationsEventTypes = new Map();
  events?.forEach((event) => {
    event.eventFunctions.forEach((eventFunction) => {
      const locationId = eventFunction.location_id;
      const e = {
        event,
        eventFunction,
      };
      if (locationsMap.has(locationId)) {
        locationsMap.get(locationId).push(e);
        locationsEventTypes.get(locationId).add(event.event_type_id);
      } else {
        locationsMap.set(locationId, [e]);
        locationsEventTypes.set(locationId, new Set([event.event_type_id]));
      }
    });
  });
  const locations = [];
  locationsMap.forEach((value, key) => {
    const location = getLocation(key, allLocations);
    if (
      location
      && location.latitude !== null
      && location.longitude !== null
      && location.latitude !== ''
      && location.longitude !== ''
      && !isNaN(location.latitude)
      && !isNaN(location.longitude)
    ) {
      const eventTypes = [...locationsEventTypes.get(key)];
      locations.push({
        location,
        events: value,
        selected: false,
        eventTypes,
      });
    }
  });
  return locations;
};

function getBand(id, bands) {
  let band = null;
  band = bands.find(
    (b) => b.id === id,
  );
  return band;
}

function getEventTypeName(id, eventTypes) {
  let type = '';
  eventTypes?.forEach((eventType) => {
    if (eventType.id === parseFloat(id)) {
      type = eventType.name;
    }
  });
  return type;
}

function getEventTypeIcon(id, eventTypes) {
  let type = '';
  eventTypes?.forEach((eventType) => {
    if (eventType.id === parseFloat(id)) {
      type = eventType.name;
    }
  });
  switch (type) {
    case 'Música':
      return MusicIcon;
    case 'Cine':
      return MovieIcon;
    case 'Teatro':
      return TheatreIcon;
    case 'Otros Eventos':
      return OtherEventsIcon;
    case 'Carnaval':
      return CarnavalIcon;
    case 'Fiestas':
      return FiestasIcon;
    default:
      return null;
  }
}

function getGenreName(id, genres) {
  let eventGenre = '';
  genres?.forEach((genre) => {
    if (parseFloat(genre.id) === parseFloat(id)) {
      eventGenre = genre.name;
    }
  });
  return eventGenre;
}

function getFirstGenreName(genresJson, genres) {
  const id = JSON.parse(genresJson)[0];
  let eventGenre = '';
  genres?.forEach((genre) => {
    if (parseFloat(genre.id) === parseFloat(id)) {
      eventGenre = genre.name;
    }
  });
  return eventGenre;
}

function getBandTypesName(id, bandTypes) {
  let bandType = '';
  bandTypes?.forEach((type) => {
    if (parseFloat(type.id) === parseFloat(id)) {
      bandType = type.name;
    }
  });
  return bandType;
}

function getOtherDataName(id, otherData) {
  let locationData = '';
  otherData?.forEach((data) => {
    if (parseFloat(data.id) === parseFloat(id)) {
      locationData = data.name;
    }
  });
  return locationData;
}

function getCityName(id, cities) {
  let city = null;
  city = cities?.find(
    (c) => parseFloat(c.id) === parseFloat(id),
  );
  return city.name;
}

function getNeighbourhoodName(id, localities) {
  let locality = null;
  locality = localities?.find(
    (l) => parseFloat(l.id) === parseFloat(id),
  );
  return locality.name;
}

function getSponsor(id, sponsors, landing = false) {
  let returnSponsor = false;
  sponsors?.forEach((sponsor) => {
    if (sponsor.id === id && (!landing || sponsor.has_landing === '1' || sponsor.has_slide === '1')) {
      returnSponsor = sponsor;
    }
  });
  return returnSponsor;
}

function getPromotion(id, sponsors) {
  let returnPromotion = '';
  sponsors?.forEach((sponsor) => {
    sponsor.sponsorPromotions?.forEach((promotion) => {
      if (promotion.id === id) {
        returnPromotion = promotion;
      }
    });
  });
  return returnPromotion;
}

function normalizeString(string) {
  return string ? string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : string;
}

function getCurrentDate() {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  const separator = '/';

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
}

function parseDate(input) {
  const parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
}

function getShortDate(input) {
  const [year, month, day] = input.split('-');
  return (`${day}/${month}/${year}`);
}

function getDate(input) {
  const parts = input.match(/(\d+)/g);
  const date = new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
  return new Date(date).toLocaleDateString('es-UY', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });
}

function getTime(input) {
  return input.slice(0, -3);
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

// formula de Haversine, devuelve distancia en KM
function calculateDistanceBetweenTwoCoordinates(lat1, lon1, lat2, lon2) {
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1))
    * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2)
    * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

function areOverlapping(eventFrom, eventTo, filterFrom, filterTo) {
  if (filterFrom < eventFrom) {
    return filterTo > eventFrom;
  }
  return filterFrom < eventTo;
}

function filtersWipe(setFiltersState) {
  setFiltersState({
    date: [],
    type: [],
    genre: [],
    location: [],
    price: [],
    sponsor: [],
    distance: [],
    city: [],
    neighbourhood: [],
  });
}

function applyTypeFilter(type, setFiltersState) {
  if (type) {
    setFiltersState({
      date: [],
      type: [type],
      genre: [],
      location: [],
      price: [],
      sponsor: [],
    });
  }
}

function applyGenreFilter(genre, type, setFiltersState) {
  if (type && genre) {
    // const filtersAux = { ...filters };
    // filtersAux.genre = [type];
    // setFiltersState(filtersAux);

    setFiltersState({
      date: [],
      type: [parseFloat(type)],
      genre: [genre],
      location: [],
      price: [],
      sponsor: [],
    });
  }
}

async function getFilteredLocations(
  filters,
  locations,
  localities,
  coords = false,
  distance = false,
) {
  let filteredLocations = [...locations];
  if (coords && distance) {
    filteredLocations = filteredLocations.filter(
      (location) => calculateDistanceBetweenTwoCoordinates(
        coords.latitude,
        coords.longitude,
        parseFloat(location.latitude),
        parseFloat(location.longitude),
      ) < distance,
    );
  }
  if (filters) {
    if (filters.sponsor && filters.sponsor.length > 0) {
      filteredLocations = filteredLocations.filter((location) => {
        let found = false;
        location.locationPromotions?.forEach((locationPromotion) => {
          if (filters.sponsor.includes(parseFloat(locationPromotion.sponsor_id))) {
            found = true;
          }
        });
        return found;
      });
    }
    if (filters.distance && filters.distance.length > 0) {
      const userCoords = await getCurrentLocation();
      filteredLocations = filteredLocations.filter((location) => {
        const dis = calculateDistanceBetweenTwoCoordinates(
          userCoords.latitude,
          userCoords.longitude,
          parseFloat(location.latitude),
          parseFloat(location.longitude),
        );
        return dis < Math.min(...filters.distance);
      });
    }
    if (filters.neighbourhood?.length > 0) {
      const neighbourhood = localities.find(
        (l) => l.id === filters.neighbourhood[0],
      );
      filteredLocations = filteredLocations.filter((location) => {
        const distance = calculateDistanceBetweenTwoCoordinates(
          parseFloat(neighbourhood.lat),
          parseFloat(neighbourhood.lng),
          parseFloat(location.latitude),
          parseFloat(location.longitude),
        );
        const maxNeighbourhoodDistance = 3; // esto deberia ser un parametro del barrio
        if (distance < maxNeighbourhoodDistance) {
          return true;
        }
        return false;
      });
    }

    /* Cities */

    if (filters.city?.length > 0) {
      const cityLocalities = localities.filter((l) => filters.city.includes(l.city_id));
      filteredLocations = filteredLocations.filter((location) => {
        let found = false;
        cityLocalities.forEach((neighbourhood) => {
          const distance = calculateDistanceBetweenTwoCoordinates(
            parseFloat(neighbourhood.lat),
            parseFloat(neighbourhood.lng),
            parseFloat(location.latitude),
            parseFloat(location.longitude),
          );
          const maxNeighbourhoodDistance = 3; // esto deberia ser un parametro del barrio
          if (distance < maxNeighbourhoodDistance) {
            found = true;
          }
        });
        return found;
      });
    }
  }
  return filteredLocations;
}

async function getFilteredEvents(
  filters,
  events,
  locations,
  localities,
  orderBy,
  sponsor = false,
  ignoreLocationFilter = false,
) {
  let result = events ? JSON.parse(JSON.stringify(events)) : '';

  // console.log(filters);

  /* Sponsor (Landing) */

  if (sponsor) {
    result = result.filter((event) => {
      let found = false;
      event.eventPromotions.forEach((eventPromotion) => {
        if (eventPromotion.sponsor_id === sponsor.id) {
          found = true;
        }
      });
      return found;
    });
  }

  /* Date */

  if (filters.date?.length > 0) {
    result = result.filter((event) => {
      // Get current date
      const curr = new Date();
      // First day of the week is the day of the month - the day of the week
      const today = new Date();

      // To get the weekend dates, we compare > thursday and < monday
      const thisThursday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 4));
      const nextMonday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 8));

      const nextMonth = new Date(curr.setDate(today.getDate() + 30));

      const month = curr.getMonth() + 1;
      const year = curr.getYear();

      event.eventFunctions = event.eventFunctions.filter((eventFunction) => {
        const date = parseDate(eventFunction.date);

        if (
          (filters.date.includes('Hoy')
          && date.toLocaleDateString('es-UY') === today.toLocaleDateString('es-UY'))
          || (filters.date.includes('Este finde')
          && date >= thisThursday && date <= nextMonday)
          || (filters.date.includes('Esta semana')
          && date <= nextMonday)
          || (filters.date.includes('Este mes')
          && date <= nextMonth
          // && date.getMonth() + 1 === month
          // && date.getYear() === year
          )
        ) {
          return true;
        }

        if (filters.date.includes('range')) {
          if (date >= new Date(filters.date[1][0]) && date <= new Date(filters.date[1][1])) {
            return true;
          }
        }
      });

      if (event.eventFunctions.length > 0) {
        return true;
      }
    });
  }

  /* Event Types */

  if (filters.type?.length > 0) {
    result = result.filter((event) => {
      //Si tiene activado para mostrar evento como Música y Fiesta:
      if (parseFloat(event.event_secondary_type_id) > 0)
        return (filters.type.includes(1) || filters.type.includes(6));
      else
        return filters.type.includes(parseFloat(event.event_type_id))
    });
  }

  /* Genres */

  if (filters.genre?.length > 0 && filters.type?.length === 1) {
    result = result.filter((event) => {
      const genres = JSON.parse(event.genres);
      let found = false;
      filters.genre.forEach((genre) => {
        if (genres.includes(genre)) {
          found = true;
        }
      });
      return found;
    });
  }

  /* Price */

  if (filters.price?.length > 0) {
    result = result.filter((event) => {
      let found = false;
      let price = parseFloat(event.price);
      let priceFrom = parseFloat(event.price_from);
      let priceTo = parseFloat(event.price_to);
      if (event.currency_id === '2') {
        price *= 40;
        priceFrom *= 40;
        priceTo *= 40;
      }
      if (
        filters.price.includes('50 - 500')
          && ((price >= 50 && price <= 500)
            || areOverlapping(
              priceFrom,
              priceTo,
              50,
              500,
            ))
      ) {
        found = true;
      }
      if (
        filters.price.includes('500 - 1000')
          && ((price >= 500
            && price <= 1000)
            || areOverlapping(
              priceFrom,
              priceTo,
              500,
              1000,
            ))
      ) {
        found = true;
      }
      if (
        filters.price.includes('> 1000')
          && (price >= 1000
            || priceFrom >= 1000
            || priceTo >= 1000)
      ) {
        found = true;
      }
      return found;
    });
  }

  /* Sponsors */

  if (filters.sponsor?.length > 0) {
    result = result.filter((event) => {
      let found = false;
      event.eventPromotions.forEach((eventPromotion) => {
        if (filters.sponsor.includes(eventPromotion.sponsor_id)) {
          found = true;
        }
      });
      return found;
    });
  }

  /* Location */

  if (filters.location?.length > 0 && !ignoreLocationFilter) {
    result = result.filter((event) => {
      event.eventFunctions = event.eventFunctions.filter((eventFunction) => {
        if (filters.location.includes(eventFunction.location_id)) {
          return true;
        }
      });
      if (event.eventFunctions.length > 0) {
        return true;
      }
    });
  }

  /* Distance */

  if (filters.distance?.length > 0 && !ignoreLocationFilter) {
    const coords = await getCurrentLocation();
    result = result.filter((event) => {
      event.eventFunctions = event.eventFunctions.filter((eventFunction) => {
        const location = locations.find(
          (location) => location.id === eventFunction.location_id,
        );
        if (location) {
          const distance = calculateDistanceBetweenTwoCoordinates(
            coords.latitude,
            coords.longitude,
            location.latitude,
            location.longitude,
          );
          if (distance <= Math.min(...filters.distance)) {
            return true;
          }
        }
      });
      if (event.eventFunctions.length > 0) {
        return true;
      }
    });
  }

  /* Localities */

  if (filters.neighbourhood?.length > 0 && !ignoreLocationFilter) {
    const neighbourhood = localities.find(
      (l) => l.id === filters.neighbourhood[0],
    );
    result = result.filter((event) => {
      event.eventFunctions = event.eventFunctions.filter((eventFunction) => {
        const location = locations.find(
          (location) => location.id === eventFunction.location_id,
        );
        if (location) {
          const distance = calculateDistanceBetweenTwoCoordinates(
            parseFloat(neighbourhood.lat),
            parseFloat(neighbourhood.lng),
            parseFloat(location.latitude),
            parseFloat(location.longitude),
          );
          const maxNeighbourhoodDistance = 3; // esto deberia ser un parametro del barrio
          if (distance < maxNeighbourhoodDistance) {
            return true;
          }
        }
      });
      if (event.eventFunctions.length > 0) {
        return true;
      }
    });
  }

  /* Cities */

  if (filters.city?.length > 0 && !ignoreLocationFilter) {
    const cityLocalities = localities.filter((l) => filters.city.includes(l.city_id));
    result = result.filter((event) => {
      let found = false;
      event.eventFunctions = event.eventFunctions.filter((eventFunction) => {
        const location = locations.find(
          (location) => location.id === eventFunction.location_id,
        );
        if (location) {
          cityLocalities.forEach((neighbourhood) => {
            const distance = calculateDistanceBetweenTwoCoordinates(
              parseFloat(neighbourhood.lat),
              parseFloat(neighbourhood.lng),
              parseFloat(location.latitude),
              parseFloat(location.longitude),
            );
            const maxNeighbourhoodDistance = 5; // esto deberia ser un parametro del barrio
            if (distance < maxNeighbourhoodDistance) {
              found = true;
            }
          });
          return found;
        }
      });
      if (event.eventFunctions.length > 0) {
        return true;
      }
    });
  }

  /* Bands */

  if (filters.band_id?.length > 0) {
    result = result.filter((event) => {
      event.eventBands = event.eventBands.filter((eventBand) => {
        if (filters.band_id === eventBand.band_id) {
          return true;
        }
      });
      if (event.eventBands.length > 0) {
        return true;
      }
    });
  }

  /* Order By */

  if (orderBy === 'relevance' || orderBy === '') {
    result = result.sort((a, b) => b.relevance - a.relevance);
    result = result.sort((a, b) => new Date(a.eventFunctions[0].date)
    - new Date(b.eventFunctions[0].date));
  }

  const priceSum = (item) => ((item.price > 0 ? parseFloat(item.price) : 0)
      + (item.price_from > 0 ? parseFloat(item.price_from) : 0)) * (item.currency_id === '2' ? 40 : 1);

  if (orderBy === 'priceHigh') {
    result = result.sort((a, b) => priceSum(b) - priceSum(a));
  }

  if (orderBy === 'priceLow') {
    result = result.sort((a, b) => priceSum(a) - priceSum(b));
  }

  if (filters.quantity) {
    result = result.slice(0, filters.quantity);
  }

  // console.log(result);

  return result;
}

// get current location with geolocation and return coordinates
function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position.coords);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

function showLoading(time, isLoading, setLoadingState) {
  setLoadingState(true);
  const loaderTimeout = setTimeout(() => {
    setLoadingState(false);
  }, time);
  if (!isLoading) {
    clearTimeout(loaderTimeout);
  }
}

export {
  getData,
  getIdFromLink,
  getEventHighlights,
  getEvent,
  getLocation,
  getLocationBranches,
  getGiveaway,
  getBand,
  getCityName,
  getNeighbourhoodName,
  getRestaurants,
  getRestaurantsWithoutBranches,
  getSponsorsWithEvents,
  getSponsorsWithRestaurants,
  getEventTypeName,
  getEventTypeIcon,
  getSponsor,
  getPromotion,
  getGenreName,
  getFirstGenreName,
  getBandTypesName,
  getOtherDataName,
  normalizeString,
  getDate,
  getShortDate,
  parseDate,
  getTime,
  filtersWipe,
  getFilteredEvents,
  getLocationsForMarkers,
  getFilteredLocations,
  calculateDistanceBetweenTwoCoordinates,
  showLoading,
  getCurrentDate,
  applyTypeFilter,
  applyGenreFilter,
};
