import { Logo } from '../../img';
import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import axios from 'axios';
import { endpointUser1 } from '../../endpoints';
import '../../style/Users/profile.css';
import ButtonSelected from '../Buttons/ButtonSelected';
import { Link } from 'react-router-dom';
import { ClickLoginNewsLetter } from '../../img';



const User = () => {
    const token = localStorage.getItem('sessionToken');
    const [events, setEvents] = useState([]);
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newsLetter, setNewsLetter] = useState(false);


    const fetchFavoriteEvents = async () => {
        setLoading(true);
        try {
            await axios.post(endpointUser1, { token }).then((res) => {
                console.log(res)
                const fetchedEvents = res.data.events;
                setEvents(fetchedEvents);
                setUser(res.data.username);
            });
        } catch (error) {
            message.error('Error al obtener datos del usuario');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoriteEvents();
    }, []);

    return (
        <>
            <Link to="/">
                <img style={{ verticalAlign: 'middle', padding: '2rem' }} src={Logo} alt="Logo de Voy" />
            </Link>
            <h1 style={{ margin: '0 auto', marginTop: '3rem', textAlign: 'center', color: '#47ffbd' }}>
                ¡Hola, {user}!
            </h1>
            <h2 style={{ marginLeft: '5rem', marginTop: '3rem', color: '#47ffbd' }}>Tipo de usuario</h2>
            <div style={{ marginLeft: '5rem', marginTop: '3rem' }}>
                <ButtonSelected
                    dataId='1'
                    nameButton='Ex1'
                />
                <ButtonSelected
                    dataId='1'
                    nameButton='Ex2'
                />
                <ButtonSelected
                    dataId='1'
                    nameButton='Ex3'
                />
                <ButtonSelected
                    dataId='1'
                    nameButton='Ex4'
                />
                <ButtonSelected
                    dataId='1'
                    nameButton='Ex5'
                />
            </div>
            <h2 style={{ marginLeft: '5rem', color: '#47ffbd' }}>
                Mis Eventos Favoritos
            </h2>
            <div style={{
                marginLeft: '5rem', marginTop: '3rem'
            }}>
                {loading ? (
                    <Spin tip="Cargando eventos..." />
                ) : (
                    <div className="eventsGrid">
                        {events.length > 0 ? (
                            events.map((event, index) => (
                                <div key={index} className="eventItem">
                                    <h3>{event.name}</h3>
                                    <p>Nombre: {event.eventBands?.join(', ')}</p>
                                    <p>Descripcion: {event.description?.join(', ')}</p>
                                    { }
                                    <img src={event.eventDesktopFile} alt={`${event.eventName} desktop`} />
                                    { }
                                </div>
                            ))
                        ) : (
                            <p>No events found</p>
                        )}
                    </div>
                )}
            </div>
            <div style={{
                marginLeft: '5rem', marginTop: '3rem', width: '100%'
            }}>
                <button
                    type="button"
                    onClick={
                        () => {
                            setNewsLetter(!newsLetter);
                        }
                    }
                    className={
                        newsLetter === true ? 'buttonNewsLetter' : 'buttonNewsLetter-click buttonNewsLetter'
                    }
                >
                    Quiero recibir recomendaciones de espectáculos
                    {' '}
                    <img src={ClickLoginNewsLetter} alt="" />
                </button>
                <span style={{ width: '100%' }}>*No abusaremos del envío de novedades, la desuscripción estará a un solo click en tu casilla.</span>
            </div >
        </>
    );
};

export default User;


