import { React, useEffect, useState } from 'react';
import '../../style/Login/login.css';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  setOpenLogin, setLoginToken, setLoginStage, setReferralLink, setShowedPopup, setLoading,
} from '../../state/AppState';
import Welcome from './components/Welcome';
import RegisterLogin from './components/registerLogin';
import RegisterDetail from './components/registerDetail';
import Soy from './components/soy';
import TypeEvent from './components/typeEvents';
import { showLoading } from '../../service';
import {
  CloseToggle, CloseToggleLogIn, User,
  RegisterLoginImg,
  BienvenidoLoginImg,
} from '../../img';
import {
  endpointApi, endpointLogin1, endpointLogin3, endpointLogin4,
} from '../../endpoints';
import { useNavigate } from "react-router-dom";


function Login({
  openLogin, setOpenLoginState, loginStage, loginToken,
  setLoginStageState, setLoginTokenState, setLoadingState,
  setReferralLinkState, referredByCode, isLoading, showedPopup, setShowedPopupState,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    setLoginStageState(1);
  }, [openLogin]);

  const [isArtistProducer, setIsArtistProducer] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);

  useEffect(() => {
  }, [isLoading]);

  async function handleContinue(data = false) {
    switch (loginStage) {
      case 1:
        try {
          const detailsData = {
            ...data,
            referral_code: referredByCode,
          };

          await axios.post(endpointLogin1, detailsData).then((res) => {
            setLoadingState(false);
            if (res.data.status === 'ok') {
              setLoginStageState(loginStage + 1);
              setLoginTokenState(res.data.token);
              setEmailInUse(false);
            }
            if (res.data.status === 'alreadyRegistered') {
              if (res.data.step != 0 && res.data.step != 6) {
                setLoginStageState(parseFloat(res.data.step) + 1);
                setReferralLinkState(res.data.link);
                setLoginTokenState(res.data.token);
                setIsArtistProducer(res.data.is_artist_producer);
              } else {
                setEmailInUse(true);
              }
            }
          }, (err) => err);
        } catch (error) {
          setLoadingState(false);
          return error;
        }
        break;
      case 2:
        try {
          const soyData = {
            is_fan: document.getElementById('loginIsFan').classList.contains('button-click') ? 1 : 0,
            is_artist: (document.getElementById('loginIsArtist').classList.contains('openInputSoyArtista')) ? 1 : 0,
            band_name: document.getElementById('loginBandName').value,
            has_venue: document.getElementById('loginHasVenue').classList.contains('openInputSoyDueño') ? 1 : 0,
            venue_name: document.getElementById('loginVenueName').value,
            token: loginToken,
          };
          await axios.post(endpointLogin3, soyData).then((res) => {
            setLoadingState(false);
            if (res.data.status === 'ok') {
              setLoginStageState(loginStage + 1);
            }
          }, (err) => err);
        } catch (error) {
          setLoadingState(false);
          return error;
        }
        break;
      case 3:
        try {
          const interests = [];
          const types = document.querySelectorAll('.TypeEventLogin .TypeEventLogin .button-click');
          types.forEach((type, index) => {
            interests.push(type.getAttribute('data-id').toString());
          });
          const genres = document.querySelectorAll('.DetailTypeEventLogin .button-click');
          genres.forEach((genre, index) => {
            interests.push(genre.getAttribute('data-id').toString());
          });
          const newsletterData = {
            newsletter: document.getElementsByClassName('buttonNewsLetter')[0].classList.contains('buttonNewsLetter-click') ? 1 : 0,
            interests,
            token: loginToken,
          };
          await axios.post(endpointLogin4, newsletterData).then((res) => {
            setLoadingState(false);
            if (res.data.status === 'ok') {
              setLoginStageState(loginStage + 1);
              setReferralLinkState(res.data.link);
              setIsArtistProducer(res.data.is_artist_producer);
            }
          }, (err) => err);
        } catch (error) {
          setLoadingState(false);
          return error;
        }
        break;
      default:
        return null;
    }
    return true;
  }

  function renderSwitch() {
    switch (loginStage) {
      case 1:
        return <RegisterLogin
          handleContinue={handleContinue}
          emailInUse={emailInUse} />;
      case 2:
        return <Soy handleContinue={handleContinue} />;
      case 3:
        return <TypeEvent handleContinue={handleContinue} />;
      case 4:
        return <Welcome loginToken={loginToken} />;
      case 7:
        return <RegisterLogin
          handleContinue={handleContinue}
          emailInUse={emailInUse} />;
      default:
        return null;
    }
  }

  const handleClick = () => {
    navigate('/signIn');
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpenLoginState(!openLogin);
          setShowedPopupState(true);
        }}
        className={
          openLogin === true ? 'loginButton openLogin user' : 'loginButton user'
        }
      >
        <div className="desktopElement">
          Iniciar Sesión | Registrarme
        </div>
        <div className="mobileElement">
          <img src={User} alt="user icon" />
        </div>
      </button>
      <div className="loginBase">
        <div className="containerLoginBase">
          <div className="subContainerLoginBase">
            <button
              type="button"
              onClick={() => {
                setOpenLoginState(false);
              }}
              className="closeLogin"
            >
              <img src={CloseToggleLogIn} alt="close" />
            </button>

            <div>
              <p className='titleLoginRegister'>Registrarme</p>
              <div className="containerInformationLogin">
                {renderSwitch()}
              </div>
              {loginStage == 1 && <button className="apiLoginButton signInButton" type="link" onClick={handleClick}>
                O INICIAR SESIÓN
              </button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  openLogin: state.app.openLogin,
  loginStage: state.app.loginStage,
  loginToken: state.app.loginToken,
  referredByCode: state.app.referredByCode,
  showedPopup: state.app.showedPopup,
});
export default connect(mapStateToProps, {
  setOpenLoginState: setOpenLogin,
  setLoginTokenState: setLoginToken,
  setLoginStageState: setLoginStage,
  setReferralLinkState: setReferralLink,
  setShowedPopupState: setShowedPopup,
  setLoadingState: setLoading,
})(Login);
